.contact-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}

.contact-heading {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-form-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-form-container label {
  display: block;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: bold;
}

.contact-form-container input,
.contact-form-container textarea {
  width: 100%;
  padding: 8px;
  border: none;
  border-bottom: 1px solid #000;
  margin-bottom: 12px;
  font-size: 18px;
}

.contact-form-container input:focus,
.contact-form-container textarea:focus {
  outline: none;
  border-bottom: 1px solid #000;
}

.contact-form-container .error {
  color: red;
  margin-top: 4px;
}

.contact-form-container button[type='submit'] {
  background-color: #333;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form-container button[type='submit']:hover {
  background-color: #555;
}
