.portraits-container {
    padding: 20px;
  }
  
  .grid {
    transition: height .5s;
  }
  
  .grid-item {
    width: 100%;
    margin-bottom: 15px;
  }
  
  .grid-item img {
    width: 100%;
    height: auto;
    display: block;
  }  
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    cursor: pointer;
  }
  
  .modal {
    max-width: 90%;
    max-height: 90%;
  }
  
  .modal img {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
  }  

  .my-masonry-grid {
    display: flex;
    width: auto;
    margin-left: -10px; /* Adjust this to change horizontal spacing */
  }
  
  .my-masonry-grid_column {
    padding-left: 10px; /* Adjust this to change horizontal spacing */
    background-clip: padding-box;
  }
  
  .my-masonry-grid_column > div { /* Change div to more specific class name */
    margin-bottom: 10px; /* Adjust this to change vertical spacing */
  }  

  .modal {
    position: relative;
  }
  
  .modal img {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    z-index: 10;
  }
  
  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  
  .grid-item {
    cursor: pointer;
    transition: filter 0.3s ease;
  }
  
  .grid-item:hover {
    filter: brightness(107%);
  }
  