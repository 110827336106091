.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  display: flex;
  height: 100vh;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .app-container {
    flex-direction: column;
  }

  .app-container > nav {
    width: 100%;
  }

  .app-container > .routes {
    width: 100%;
  }
}

.app-container > nav {
  flex: 0 0 20%;
  padding: 20px;
}

.app-container > .routes {
  flex: 1;
  padding: 20px;
}

.header-text {
  margin-top: 50px;
  font-size: 32px;
  font-weight: bold;
  text-align: left;
}

.nav-link {
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
  transition: font-size 0.2s;
}

.nav-link:hover {
  color: #18d9bb;
  font-size: 28px;
}

.contact-form-container input,
.contact-form-container textarea {
  width: 100%;
  padding: 8px;
  border: none;
  border-bottom: 1px solid #000;
  margin-bottom: 12px;
  font-size: 18px;
}

.contact-form-container input:focus,
.contact-form-container textarea:focus {
  outline: none;
  border-bottom: 1px solid #000;
}

.nav-toggler {
  cursor: pointer;
  width: 30px;
  height: 22px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #000;
}

.nav-toggler span {
  width: 100%;
  height: 4px;
  background-color: #000; /* Changed color to black */
  z-index: 1;
}

.nav-toggler.closed span:nth-child(1),
.nav-toggler.closed span:nth-child(2),
.nav-toggler.closed span:nth-child(3) {
  position: absolute;
}

.nav-toggler.closed span:nth-child(1) {
  top: 0;
}

.nav-toggler.closed span:nth-child(2) {
  top: 9px;
}

.nav-toggler.closed span:nth-child(3) {
  top: 18px;
}

.nav-toggler.open span:nth-child(1) {
  transform: rotate(45deg) translate(-2px, 7px);
  position: absolute;
}

.nav-toggler.open span:nth-child(2) {
  opacity: 0;
}

.nav-toggler.open span:nth-child(3) {
  transform: rotate(-45deg) translate(-2px, -7px);
  position: absolute;
}

.nav-menu {
  display: none;
  flex-direction: column;
}

.nav-menu.nav-open {
  display: flex;
}

@media screen and (max-width: 768px) {
  .nav-toggler {
    display: block;
  }

  .nav-menu {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .nav-toggler {
    display: none;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
  }
}

/* Hamburger Menu */
.hamburger-bar {
  width: 100%;
  height: 4px;
  background-color: #000;
  margin-bottom: 4px;
  transition: transform 0.3s ease-in-out;
}

.nav-toggler.closed .hamburger-bar:nth-child(1),
.nav-toggler.closed .hamburger-bar:nth-child(2),
.nav-toggler.closed .hamburger-bar:nth-child(3) {
  transform-origin: center;
}

.nav-toggler.closed .hamburger-bar:nth-child(1) {
  transform: translateY(0) rotate(0);
}

.nav-toggler.closed .hamburger-bar:nth-child(2) {
  transform: translateY(6px) rotate(0);
}

.nav-toggler.closed .hamburger-bar:nth-child(3) {
  transform: translateY(12px) rotate(0);
}

.nav-toggler.open .hamburger-bar:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
}

.nav-toggler.open .hamburger-bar:nth-child(2) {
  transform: translateY(6px) rotate(-45deg);
}

.nav-toggler.open .hamburger-bar:nth-child(3) {
  transform: translateY(6px) rotate(0);
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.site-title {
  font-family: 'Montserrat', sans-serif;
}

.title-photos {
  color: #18d9bb;
}

.nav-link.active {
  color: #18d9bb;
}
